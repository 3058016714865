<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button></ion-menu-button>
          <ion-back-button></ion-back-button>
        </ion-buttons>
        <ion-title>Mail Inbox</ion-title>
        <ion-buttons slot="end">
         <ion-fab-button size="small" slot="start">
         <ion-icon :icon="add"></ion-icon>
</ion-fab-button>
      </ion-buttons>
      </ion-toolbar>
    </ion-header>
<ion-content> 
   <Suspense>
    <template #default>
    <!--<ion-content> -->
  <Ml></Ml> 
   <!-- <Mliste></Mliste> -->
 <!--  </ion-content> -->
   </template>
    <template #fallback>
        <!-- <ion-content> -->
    loading...
    <!-- </ion-content> -->
    </template>
    </Suspense> 
    </ion-content>
 </ion-page>
</template>
<script>

//import  Mliste  from '@/views/Mliste.vue';

import  Ml  from '@/views/Ml.vue';

import {  archiveOutline, archiveSharp, bookmarkOutline, bookmarkSharp, heartOutline, heartSharp, mailOutline, mailSharp, paperPlaneOutline, paperPlaneSharp, trashOutline, trashSharp, warningOutline, warningSharp, add } from 'ionicons/icons';


import {ref, computed, watch, reactive, onMounted, defineComponent} from 'vue'

  import { useRoute, useRouter } from 'vue-router';
import shared from "@/shared/shared";



export default defineComponent({
  name: "Mail",
 components:{
   //Mliste
   Ml
 }
,  inject:{shared: "shared"},
  data(){
    return {
      add,
 }
  }
})
</script>

<style scoped>

</style>
