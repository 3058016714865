<template>
          <ion-header translucent>
            <ion-toolbar>
              <ion-title>Modal Content</ion-title>
              <ion-buttons slot="end">
              <ion-button @click="emit('modalclosed')">Close</ion-button>
              </ion-buttons>
            </ion-toolbar>
          </ion-header>
          <ion-content fullscreen>

   <ion-card>
          <ion-card-subtitle> Email  </ion-card-subtitle>
          <ion-card-subtitle>{{ email.subject }}</ion-card-subtitle>
          <ion-card-content><div v-html="marked(email.body)"/></ion-card-content>
    </ion-card>
    </ion-content>
</template>
<script>

import {format} from 'date-fns'
import marked from 'marked'
import shared from "@/shared/shared";

import {ref} from 'vue'

export default {
  name: "Mv",
  inject:{shared: "shared"},
  setup (props,{emit})
  {
//emit('modalclosed');
    return {
      format,
      emit,
      marked,
   }
  },
  props:{
    email:{
      type: Object,
      required:true
    }
  }
}
</script>
<style scoped>

</style>
