<template>
   <ion-card>
          <ion-card-subtitle> Email  </ion-card-subtitle>
          <ion-card-subtitle>{{ email.subject }}</ion-card-subtitle>
          <ion-card-content><div v-html="marked(email.body)"/></ion-card-content>
    </ion-card>
</template>
<script>

import {format} from 'date-fns'
import marked from 'marked'
import shared from "@/shared/shared";

import {ref} from 'vue'

export default {
  name: "Mv",
  inject:{shared: "shared"},
  setup (props){
    return {
      format,
      marked
    }
  },
  props:{
    email:{
      type: Object,
      required:true
    }
  },
  methods:{
}
}
</script>
<style scoped>

</style>
