<template>
   <ion-list>
    <ion-item v-for="email in emails" :key="email._id" class="clickable" :color="email.read ?primary:secondary" @click="openEmail(email)">
    <ion-label> 
    <ion-checkbox slot="start"></ion-checkbox>
   <ion-text color="primary">
          {{email.from}} - {{email.read}}
        </ion-text>
        <ion-text color="secondary">
          <p><strong>{{email.subject}}</strong> - {{email.body}}</p>
        </ion-text>
        <ion-text color="secondary">
          <p>{{format( new Date(email.received), 'MMM do yyyy')}}</p>
        </ion-text>
        </ion-label><ion-label>
            <ion-button slot="end" @click="archive(email)">Archive</ion-button>

</ion-label>
    </ion-item>
    </ion-list>
   <ion-modal :is-open="openedEmail"  @onDidDismiss="openedEmail=null"
   @modalclosed="openedEmail=null">
  <Mv :email="openedEmail" @modalclosed="openedEmail=null"></Mv>
  </ion-modal>

</template>
<script>

import {format} from 'date-fns'
import shared from "@/shared/shared";

import  Mv  from '@/views/Mv.vue';

import  Modalv  from '@/views/Modalv.vue';

import {ref} from 'vue'

export default {
  name: "Ml",
   components:{
   //Mliste
   Mv,
   Modalv
 },

  inject:{shared: "shared"},
  methods:{
    readEmail(email){
      email.read=true;
      this.updateEmail(email)
   },
    archiveEmail(email){
      email.archived=true
      this.updateEmail(email)
   },
    updateEmail(email){
      alert(JSON.stringify(email))
      //update doc in localDb
      shared.localdb.get(email._id).then(function (doc) {
        return localdb.put(email);
      });
    },
    openEmail(email){
      //alert('ici')
      //alert(JSON.stringify(email))
      this.openedEmail = email;
      //this.readEmail(email);
    },
    handleModalClosed(){
    alert('ici')
      this.openedEmail=null;
    }
    
 },

    async setup(props,context){
//        let response = await shared.localdb.find({
        let {docs: emails} = await shared.localdb.find({
            selector: {
                type: 'email'
            }
        })
        //let emails = response.docs;

    //await new Promise(resolve => setTimeout(resolve,3000));
    
    return {
      format,
      emails,
      openedEmail:ref(null),
   }
}
}
</script>
<style scoped>

</style>
